var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "j-super-query-box" },
    [
      _vm._t(
        "button",
        function () {
          return [
            _vm.superQueryFlag
              ? _c(
                  "a-tooltip",
                  _vm._b(
                    { attrs: { mouseLeaveDelay: 0.2 } },
                    "a-tooltip",
                    _vm.tooltipProps,
                    false
                  ),
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.tooltipProps))]
                    ),
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _c("span", [_vm._v("已有高级查询条件生效")]),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c("a", { on: { click: _vm.handleReset } }, [
                          _vm._v("清空"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "a-button-group",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handleOpen },
                          },
                          [
                            _c("a-icon", {
                              attrs: {
                                type: "appstore",
                                theme: "twoTone",
                                spin: "",
                              },
                            }),
                            _c("span", [_vm._v("高级查询")]),
                          ],
                          1
                        ),
                        _vm.izMobile
                          ? _c("a-button", {
                              attrs: { type: "primary", icon: "delete" },
                              on: { click: _vm.handleReset },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _c(
                  "a-button",
                  {
                    attrs: { type: "primary", icon: "filter" },
                    on: { click: _vm.handleOpen },
                  },
                  [_vm._v("高级查询")]
                ),
          ]
        },
        {
          isActive: _vm.superQueryFlag,
          isMobile: _vm.izMobile,
          open: _vm.handleOpen,
          reset: _vm.handleReset,
        }
      ),
      _c(
        "j-modal",
        {
          staticClass: "j-super-query-modal",
          staticStyle: { top: "5%", "max-height": "95%" },
          attrs: {
            title: "高级查询构造器",
            width: 1000,
            visible: _vm.visible,
            mask: false,
            fullscreen: _vm.izMobile,
          },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "div",
                { staticStyle: { float: "left" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading, icon: "reload" },
                      on: { click: _vm.handleReset },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("保存查询条件")]
                  ),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("关闭")]
              ),
              _c(
                "a-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    icon: "search",
                    type: "primary",
                  },
                  on: { click: _vm.handleOk },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 24 - 5 } },
                    [
                      _vm.queryParamsModel.length === 0
                        ? _c(
                            "a-empty",
                            { staticStyle: { "margin-bottom": "12px" } },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "description" },
                                  slot: "description",
                                },
                                [
                                  _c("span", [_vm._v("没有任何查询条件")]),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c("a", { on: { click: _vm.handleAdd } }, [
                                    _vm._v("点击新增"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            "a-form",
                            { attrs: { layout: "inline" } },
                            [
                              _c(
                                "a-row",
                                { staticStyle: { "margin-bottom": "12px" } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { md: 12, xs: 24 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            label: "过滤条件匹配",
                                            labelCol: { md: 6, xs: 24 },
                                            wrapperCol: { md: 18, xs: 24 },
                                          },
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                getPopupContainer: (node) =>
                                                  node.parentNode,
                                              },
                                              model: {
                                                value: _vm.matchType,
                                                callback: function ($$v) {
                                                  _vm.matchType = $$v
                                                },
                                                expression: "matchType",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "and" } },
                                                [
                                                  _vm._v(
                                                    "AND（所有条件都要求匹配）"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "or" } },
                                                [
                                                  _vm._v(
                                                    "OR（条件中的任意一个匹配）"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.queryParamsModel,
                                function (item, index) {
                                  return _c(
                                    "a-row",
                                    {
                                      key: index,
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { type: "flex", gutter: 16 },
                                    },
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "12px",
                                          },
                                          attrs: { md: 8, xs: 24 },
                                        },
                                        [
                                          _c("a-tree-select", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              showSearch: "",
                                              treeData: _vm.fieldTreeData,
                                              dropdownStyle: {
                                                maxHeight: "400px",
                                                overflow: "auto",
                                              },
                                              placeholder: "选择查询字段",
                                              allowClear: "",
                                              treeDefaultExpandAll: "",
                                              getPopupContainer: (node) =>
                                                node.parentNode,
                                            },
                                            on: {
                                              select: (val, option) =>
                                                _vm.handleSelected(
                                                  option,
                                                  item
                                                ),
                                            },
                                            model: {
                                              value: item.field,
                                              callback: function ($$v) {
                                                _vm.$set(item, "field", $$v)
                                              },
                                              expression: "item.field",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "12px",
                                          },
                                          attrs: { md: 4, xs: 24 },
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              attrs: {
                                                placeholder: "匹配规则",
                                                value: item.rule,
                                                getPopupContainer: (node) =>
                                                  node.parentNode,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleRuleChange(
                                                    item,
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "eq" } },
                                                [_vm._v("等于")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "like" } },
                                                [_vm._v("包含")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: {
                                                    value: "right_like",
                                                  },
                                                },
                                                [_vm._v("以..开始")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                {
                                                  attrs: { value: "left_like" },
                                                },
                                                [_vm._v("以..结尾")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "in" } },
                                                [_vm._v("在...中")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "ne" } },
                                                [_vm._v("不等于")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "gt" } },
                                                [_vm._v("大于")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "ge" } },
                                                [_vm._v("大于等于")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "lt" } },
                                                [_vm._v("小于")]
                                              ),
                                              _c(
                                                "a-select-option",
                                                { attrs: { value: "le" } },
                                                [_vm._v("小于等于")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "12px",
                                          },
                                          attrs: { md: 8, xs: 24 },
                                        },
                                        [
                                          item.type === "sel_search"
                                            ? _c("j-search-select-tag", {
                                                attrs: {
                                                  dict: _vm.getDictInfo(item),
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              })
                                            : item.type === "list_multi"
                                            ? [
                                                item.options
                                                  ? _c("j-multi-select-tag", {
                                                      attrs: {
                                                        options: item.options,
                                                        placeholder: "请选择",
                                                      },
                                                      model: {
                                                        value: item.val,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "val",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "item.val",
                                                      },
                                                    })
                                                  : _c("j-multi-select-tag", {
                                                      attrs: {
                                                        dictCode:
                                                          _vm.getDictInfo(item),
                                                        placeholder: "请选择",
                                                      },
                                                      model: {
                                                        value: item.val,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "val",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "item.val",
                                                      },
                                                    }),
                                              ]
                                            : item.dictCode
                                            ? [
                                                item.type === "table-dict"
                                                  ? [
                                                      _c("j-popup", {
                                                        attrs: {
                                                          code: item.dictTable,
                                                          field: item.dictCode,
                                                          orgFields:
                                                            item.dictCode,
                                                          destFields:
                                                            item.dictCode,
                                                          multi: true,
                                                        },
                                                        model: {
                                                          value: item.val,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "val",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.val",
                                                        },
                                                      }),
                                                    ]
                                                  : [
                                                      _c("j-multi-select-tag", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.allowMultiple(
                                                                item
                                                              ),
                                                            expression:
                                                              "allowMultiple(item)",
                                                          },
                                                        ],
                                                        attrs: {
                                                          dictCode:
                                                            item.dictCode,
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value: item.val,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "val",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.val",
                                                        },
                                                      }),
                                                      _c("j-dict-select-tag", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              !_vm.allowMultiple(
                                                                item
                                                              ),
                                                            expression:
                                                              "!allowMultiple(item)",
                                                          },
                                                        ],
                                                        attrs: {
                                                          dictCode:
                                                            item.dictCode,
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value: item.val,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "val",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.val",
                                                        },
                                                      }),
                                                    ],
                                              ]
                                            : item.type === "popup"
                                            ? _c(
                                                "j-popup",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      value: item.val,
                                                      "group-id": "superQuery",
                                                      multi: true,
                                                    },
                                                    on: {
                                                      input: (e, v) =>
                                                        _vm.handleChangeJPopup(
                                                          item,
                                                          e,
                                                          v
                                                        ),
                                                    },
                                                  },
                                                  "j-popup",
                                                  item.popup,
                                                  false
                                                )
                                              )
                                            : item.type === "select-user" ||
                                              item.type === "sel_user"
                                            ? _c("j-select-multi-user", {
                                                attrs: {
                                                  buttons: false,
                                                  multiple: false,
                                                  placeholder: "请选择用户",
                                                  returnKeys: [
                                                    "id",
                                                    item.customReturnField ||
                                                      "username",
                                                  ],
                                                },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              })
                                            : item.type === "select-depart" ||
                                              item.type === "sel_depart"
                                            ? _c("j-select-depart", {
                                                attrs: {
                                                  multi: false,
                                                  placeholder: "请选择部门",
                                                  customReturnField:
                                                    item.customReturnField ||
                                                    "id",
                                                },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              })
                                            : item.options instanceof Array
                                            ? _c("a-select", {
                                                attrs: {
                                                  options: item.options,
                                                  allowClear: "",
                                                  placeholder: "请选择",
                                                  mode: _vm.allowMultiple(item)
                                                    ? "multiple"
                                                    : "",
                                                },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              })
                                            : item.type === "area-linkage" ||
                                              item.type === "pca"
                                            ? _c("j-area-linkage", {
                                                staticStyle: { width: "100%" },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              })
                                            : item.type == "date"
                                            ? _c("j-date", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择日期",
                                                },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              })
                                            : item.type == "datetime"
                                            ? _c("j-date", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择时间",
                                                  "show-time": true,
                                                  "date-format":
                                                    "YYYY-MM-DD HH:mm:ss",
                                                },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              })
                                            : item.type === "time"
                                            ? _c("a-time-picker", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  value: item.val
                                                    ? _vm.moment(
                                                        item.val,
                                                        "HH:mm:ss"
                                                      )
                                                    : null,
                                                  format: "HH:mm:ss",
                                                },
                                                on: {
                                                  change: (time, value) =>
                                                    (item.val = value),
                                                },
                                              })
                                            : item.type == "int" ||
                                              item.type == "number"
                                            ? _c("a-input-number", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入数值",
                                                },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              })
                                            : item.type == "switch"
                                            ? _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value: item.val,
                                                    callback: function ($$v) {
                                                      _vm.$set(item, "val", $$v)
                                                    },
                                                    expression: "item.val",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-select-option",
                                                    { attrs: { value: "Y" } },
                                                    [_vm._v("是")]
                                                  ),
                                                  _c(
                                                    "a-select-option",
                                                    { attrs: { value: "N" } },
                                                    [_vm._v("否")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("a-input", {
                                                attrs: {
                                                  placeholder: "请输入值",
                                                },
                                                model: {
                                                  value: item.val,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "val", $$v)
                                                  },
                                                  expression: "item.val",
                                                },
                                              }),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "12px",
                                          },
                                          attrs: { md: 4, xs: 0 },
                                        },
                                        [
                                          _c("a-button", {
                                            attrs: { icon: "plus" },
                                            on: { click: _vm.handleAdd },
                                          }),
                                          _vm._v(" \n                "),
                                          _c("a-button", {
                                            attrs: { icon: "minus" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDel(index)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "12px",
                                            "text-align": "right",
                                          },
                                          attrs: { md: 0, xs: 24 },
                                        },
                                        [
                                          _c("a-button", {
                                            attrs: { icon: "plus" },
                                            on: { click: _vm.handleAdd },
                                          }),
                                          _vm._v(" \n                "),
                                          _c("a-button", {
                                            attrs: { icon: "minus" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDel(index)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 5 } },
                    [
                      _c(
                        "a-card",
                        {
                          staticClass: "j-super-query-history-card",
                          attrs: { bordered: true },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v("\n              保存的查询\n            ")]
                          ),
                          _vm.saveTreeData.length === 0
                            ? _c("a-empty", {
                                staticClass: "j-super-query-history-empty",
                                attrs: { description: "没有保存任何查询" },
                              })
                            : _c("a-tree", {
                                staticClass: "j-super-query-history-tree",
                                attrs: {
                                  showIcon: "",
                                  treeData: _vm.saveTreeData,
                                  selectedKeys: [],
                                },
                                on: { select: _vm.handleTreeSelect },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: { title: "请输入保存的名称", visible: _vm.prompt.visible },
              on: {
                cancel: function ($event) {
                  _vm.prompt.visible = false
                },
                ok: _vm.handlePromptOk,
              },
            },
            [
              _c("a-input", {
                model: {
                  value: _vm.prompt.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.prompt, "value", $$v)
                  },
                  expression: "prompt.value",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }